import { ClientUserData, Course, Program } from '@/types/interfaces';
import { convertToDate } from '@/utilities/helpers';
import { format } from 'date-fns';

export const EMAIL_FROM_NAME = 'Coachplan';

export const EMAIL_CLIENT_CREATED = {
  subject: 'Din bruger er blevet oprettet',
  text: (client) => {
    return `Tillykke, din træner har oprettet dig som bruger!<br />
            Inden længe vil du kunne se alt det som din træner tildeler dig, herunder programmer, forløb, opgaver mv.
            Du kan hente app'en på følgende link: <a href="https://klient.coachplan.dk">Hent app</a><br />
            Du kan logge ind med din email, samt følgende password: <strong>${client.tempAppPassword}</strong><br />Det anbefales på det kraftigste du ændrer dit password så kun du kender til dit password!`;
  }
};

export const CLIENT_ADDED_TO_COURSE = {
  subject: 'Du er tilføjet et træningsforløb!',
  text: (course: Course) => {
    return `Din træner har føjet dig til forløbet ${course.title} som starter ${
      // @ts-ignore
      course.start?.seconds ? convertToDate(course.start) : format(new Date(course.start), 'dd-MM-yyyy')
    }!
          Du vil fremadrettet modtage mails på de dage hvor du har opgaver der venter, eller hvis du får tildelt et nyt træningsprogram! Log ind allerede nu og se om du har en opgave der venter!`;
  }
};

export const CLIENT_ADDED_TO_PROGRAM = {
  subject: 'Du har fået nyt træningsprogram',
  text: (client: ClientUserData, program: Program) => {
    return `Gode nyheder ${client.firstName}!
           Du er blevet tildelt et nyt program (${program.title}) af din træner. 
           Du kan tilgå dit nye program ved at logge ind på din profil! Her vil det være synligt under dine aktive programmer!`;
  }
};
