<template>
  <NConfigProvider :theme-overrides="themeOverrides" :theme="null">
    <NAlert v-if="isUserOffline" type="error">Vi kan ikke oprette forbindelse til internettet. For at bruge Coachplan skal du være online. Tjek venligst din internetforbindelse og prøv igen.</NAlert>

    <NGlobalStyle />
    <NDialogProvider>
      <NMessageProvider placement="bottom-right" :duration="3000">
        <router-view />
      </NMessageProvider>
    </NDialogProvider>
  </NConfigProvider>
</template>

<script setup lang="ts">
import { GlobalThemeOverrides, NConfigProvider, NDialogProvider, NGlobalStyle, NMessageProvider, NAlert } from 'naive-ui';
import 'animate.css';
import '@/assets/scss/main.scss';
import 'vue-next-select/dist/index.css';
import { onBeforeUnmount, onMounted, ref } from 'vue';

const isUserOffline = ref(false);

const themeOverrides: GlobalThemeOverrides = {
  common: {
    primaryColor: '#0fffac',
    successColor: '#0fffac',
    fontWeightStrong: '600',
    primaryColorPressed: 'transparent',
    iconColorPressed: '#0fffac',
    iconColorHover: '#0fffac',
    iconColor: '#1c1157',
    fontSize: '0.8125rem'
  },
  Button: {
    borderPrimary: '0px solid transparent',
    borderHoverPrimary: '0px solid transparent',
    borderFocusPrimary: '0px solid transparent',
    borderPressedPrimary: '0px solid transparent',
    borderPressed: '0px solid transparent',
    colorFocus: 'none',
    colorFocusPrimary: '#0fffac',
    textColorPrimary: '#207c5d',
    textColorFocusPrimary: '#207c5d',
    textColorPressedPrimary: '#207c5d'
  }
};

const setUserStatus = (offline: boolean) => {
  isUserOffline.value = offline;
};

onMounted(() => {
  window.addEventListener('offline', () => setUserStatus(true));
  window.addEventListener('online', () => setUserStatus(false));
});

onBeforeUnmount(() => {
  window.removeEventListener('offline', () => setUserStatus(true));
  window.removeEventListener('online', () => setUserStatus(false));
});
</script>
