// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { initializeAnalytics, getAnalytics } from 'firebase/analytics';
import { getFirestore, initializeFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCqg5XvkylVpBj4XD03DSRQQ3ye-sncD5s',
  authDomain: 'fitnessappsaas.firebaseapp.com',
  projectId: 'fitnessappsaas',
  storageBucket: 'fitnessappsaas.appspot.com',
  messagingSenderId: '903931436216',
  appId: '1:903931436216:web:8367fb6989667368d3684d',
  measurementId: 'G-BF78BWTSP5'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
initializeAnalytics(app);

initializeFirestore(app, {
  ignoreUndefinedProperties: true
});
export const analytics = getAnalytics(app);
export const db = getFirestore();
export const functions = getFunctions(app, 'europe-west2');
