import { signOut, getAuth } from '@firebase/auth';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  setDoc,
  startAfter,
  updateDoc,
  where,
  Query,
  DocumentData,
  arrayUnion,
  arrayRemove,
  getCountFromServer,
  QuerySnapshot,
  DocumentSnapshot
} from 'firebase/firestore';
import { CompanyData, Lesson, MeasurementTemplateItem, PaginatedResult, Task, UserData } from '@/types/interfaces';
import { capitalizeFirstLetter, capitalizeWords, removeUndefinedValuesFromObject } from '@/utilities/helpers';
import firebase from 'firebase/compat';
import OrderByDirection = firebase.firestore.OrderByDirection;
import store from '@/store';
import WhereFilterOp = firebase.firestore.WhereFilterOp;
import { FirebaseError } from 'firebase/app';

const db = getFirestore();
const auth = getAuth();

export interface Document {
  id: string;
  [key: string]: string;
}

export const getAllDocs = async (path: string, orderByProp = 'createdAt') => {
  const items = [];
  const querySnapshot = await getDocs(query(collection(db, `${path}`), orderBy(orderByProp, 'desc')));
  querySnapshot.forEach((doc) => {
    items.push({
      id: doc.id,
      ...doc.data()
    });
  });
  return items;
};

export const createCustomDocumentWithId = async (path, documentId, data) => {
  try {
    await setDoc(doc(db, path, documentId), {
      ...data,
      createdAt: new Date()
    });
  } catch (e) {
    throw new Error('Error creating doc: ' + e);
  }
};

export const setDocument = async <Data>(path: string, data: Data, documentId: string | undefined = undefined, date: Date = new Date()) => {
  data = removeUndefinedValuesFromObject(data);
  try {
    if (documentId) {
      await setDoc(
        doc(db, path, documentId),
        {
          ...data,
          updatedAt: date
        },
        { merge: true }
      );
      return documentId;
    } else {
      const document = await addDoc(collection(db, `${path}`), {
        ...data,
        createdAt: date,
        updatedAt: date
      });
      return document.id;
    }
  } catch (e) {
    throw new Error('cant create document: ' + e);
  }
};

export const queryDocuments = async <T extends { id?: string }>(
  collectionPath: string,
  key?: string,
  value?: string | boolean,
  orderByField = 'createdAt',
  secondKey?: string,
  secondValue?: string | number | boolean,
  maxLimit?: number,
  orderDirection: OrderByDirection = 'desc'
): Promise<T[]> => {
  // Adjusted return type to include string for error messages
  try {
    const items: T[] = [];
    const collectionRef = collection(db, collectionPath);

    let q = query(collectionRef);

    if (key && value !== undefined) {
      q = query(q, value === null ? where(key, '==', null) : where(key, '==', value));
    }

    if (secondKey) {
      q = query(q, where(secondKey, '==', secondValue));
    }

    q = query(q, orderBy(orderByField, orderDirection));

    if (maxLimit) {
      q = query(q, limit(maxLimit));
    }

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      const docId = doc.id;
      const data = doc.data();
      const item = {
        ...data,
        id: docId
      } as T; // Type assertion here
      items.push(item);
    });

    return items;
  } catch (error) {
    // Log the error or handle it as needed
    handleFirebaseError(error);
    throw new Error('An error occurred while querying documents: ' + error);
  }
};

const getLastVisibleDoc = async (query: Query, startAt: number) => {
  const snapshot = await getDocs(query);
  if (snapshot.empty) {
    return null;
  }
  const docs = snapshot.docs;
  return docs[startAt - 1]; // Firestore query is 0-based, so subtract 1
};

export const queryDocumentsPaginate = async <T extends { id?: string }>(
  collectionPath: string,
  filters: { key: string; value: string | boolean | number; operator?: WhereFilterOp }[] = [],
  startAtDoc?: number,
  numOfResult = 10,
  orderByField = 'createdAt',
  orderDirection: 'asc' | 'desc' = 'desc'
): Promise<PaginatedResult<T>> => {
  const items: T[] = [];
  const collectionRef = collection(db, collectionPath);

  let q = query(collectionRef);

  // Apply multiple filters dynamically
  if (filters.length === 0) {
    console.warn('No filters provided, fetching all documents.');
  }

  filters.forEach(({ key, value, operator = '==' }) => {
    if (!key || value === undefined) {
      console.error('Invalid filter: key or value missing', { key, value });
      return; // Skip invalid filters
    }
    q = query(q, where(key, operator, value));
  });

  // Initialize totalCount to a default value
  let totalCount = 0;

  try {
    // Attempt to get the count from the server
    const count = await getCountFromServer(q);
    const countData = count.data();
    totalCount = countData ? countData.count : 0;
  } catch (error) {
    console.error('Error getting count from server:', error);
  }

  q = query(q, orderBy(orderByField, orderDirection));

  if (startAtDoc !== undefined && startAtDoc > 0) {
    try {
      // Attempt to get the last visible document for pagination
      const lastVisibleDoc = await getLastVisibleDoc(q, startAtDoc);
      if (lastVisibleDoc) {
        q = query(q, startAfter(lastVisibleDoc));
      }
    } catch (error) {
      console.error('Error getting last visible document:', error);
    }
  }

  if (numOfResult) {
    q = query(q, limit(numOfResult));
  }

  let hasMore = false;

  try {
    // Fetch documents from the query
    const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(q);

    // Check if there are any documents in the querySnapshot
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc: DocumentSnapshot<DocumentData>) => {
        const docId = doc.id;
        const data = doc.data();
        if (data) {
          const item = {
            ...data,
            id: docId
          } as T;
          items.push(item);
        }
      });

      // If the number of fetched items is equal to the requested limit,
      // it means there might be more documents to fetch
      hasMore = querySnapshot.size === numOfResult;
    }
  } catch (error) {
    console.error('Error getting documents:', error);
  }

  return { items, totalCount, hasMore };
};

export const queryDocCollectionPaginate = async <T extends { id?: string }>(collectionPath: string, startAtDoc?: number, numOfResult = 10) => {
  const items: T[] = [];
  const collectionRef = collection(db, collectionPath);

  let q = query(collectionRef);

  const count = await getCountFromServer(q);
  const totalCount = count.data().count;

  q = query(q, orderBy('createdAt', 'desc'));

  if (startAtDoc > 0) {
    const lastVisibleDoc = await getLastVisibleDoc(q, startAtDoc);
    q = query(q, startAfter(lastVisibleDoc));
  }

  if (numOfResult) {
    q = query(q, limit(numOfResult));
  }

  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    const docId = doc.id;
    const data = doc.data();
    const item = {
      ...data,
      id: docId
    } as T; // Type assertion here
    items.push(item);
  });

  return {
    items,
    totalCount
  };
};

export const deleteDocument = async (collectionName: string, docId: string) => {
  return await deleteDoc(doc(db, collectionName, docId));
};

export const getCountOfCollection = async (
  collectionName: string,
  key: string,
  value: string,
  secondKey?: string,
  secondValue?: string | boolean,
  thirdKey?: string,
  thirdValue?: string | boolean,
  fourthKey?: string,
  fourthValue?: string | boolean,
  limitCount?: number
) => {
  try {
    const coll = collection(db, collectionName);
    let fb_query: Query<DocumentData>;

    // Base case: only one key-value pair
    if (!secondKey && !thirdKey && !fourthKey) {
      fb_query = query(coll, where(key, '==', value));
    }
    // Case with second key-value pair
    else if (secondKey && !thirdKey && !fourthKey) {
      fb_query = query(coll, where(key, '==', value), where(secondKey, '==', secondValue));
    }
    // Case with second and third key-value pairs
    else if (secondKey && thirdKey && !fourthKey) {
      fb_query = query(coll, where(key, '==', value), where(secondKey, '==', secondValue), where(thirdKey, '==', thirdValue));
    }
    // Case with all four key-value pairs
    else if (secondKey && thirdKey && fourthKey) {
      fb_query = query(coll, where(key, '==', value), where(secondKey, '==', secondValue), where(thirdKey, '==', thirdValue), where(fourthKey, '==', fourthValue));
    }

    // Apply limit if limitCount is provided
    if (limitCount) {
      fb_query = query(fb_query, limit(limitCount));
    }

    const snapshot = await getCountFromServer(fb_query);
    const count = snapshot.data().count;
    return count;
  } catch (e) {
    handleFirebaseError(e);
    throw new Error('error in getCountOfCollection: ' + e);
  }
};

export const queryMessagesNew = (path: string, numberOfMessages: number, toUserId: string, fromUserId: string) => {
  const docsQuery = query(collection(db, path), where('to', '==', toUserId), where('from', '==', fromUserId), orderBy('createdAt', 'desc'), limit(numberOfMessages ?? 10));
  return docsQuery;
};

export const getDocumentsWithLimit = async <T extends { id?: string }>(collectionPath: string, numberOfDocs: number) => {
  const items: T[] = [];
  const q = query(collection(db, collectionPath), orderBy('createdAt', 'desc'), limit(numberOfDocs));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    items.push({
      ...data,
      id: doc.id
    } as T);
  });
  return items;
};

export const searchDocuments = async <T extends { id?: string }>(collectionPath: string, key = 'name', term: string, numberOfDocs = 10, secondKey?: string, secondKeyValue?: string): Promise<T[]> => {
  const items: Map<string, T> = new Map();

  // Create an array of search fields, including key, key_lowercase, key_partial, and name_splitted
  const searchFields = [key, `${key}_lowercase`, `${key}_partial`, `${key}_splitted`];

  const searchCases = [capitalizeFirstLetter(term), capitalizeWords(term), term.toLowerCase(), term];

  const queries = searchCases.flatMap((searchTerm) =>
    searchFields.map((field) => {
      let mainQuery: Query;

      if (field === `${key}_splitted`) {
        // If searching in name_splitted field, check if any word in the array matches the search term
        mainQuery = query(collection(db, collectionPath), where(field, 'array-contains', searchTerm), limit(numberOfDocs));
      } else {
        // Otherwise, perform the usual search
        mainQuery = query(collection(db, collectionPath), where(field, '>=', searchTerm), where(field, '<=', searchTerm + '\uf8ff'), limit(numberOfDocs));
      }

      if (secondKey && secondKeyValue) {
        mainQuery = query(mainQuery, where(secondKey, '==', secondKeyValue));
      }

      return mainQuery;
    })
  );

  const querySnapshots = await Promise.all(queries.map(async (q) => await getDocs(q)));

  querySnapshots.forEach((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      const data = doc.data();

      // Check if an item with the same doc.id already exists before adding
      if (!items.has(data.id)) {
        items.set(data.id, {
          ...data,
          id: doc.id
        } as T);
      }
    });
  });
  // Convert the Map values to an array before returning
  return Array.from(items.values());
};

export const getUserDocument = async (userId: string) => {
  try {
    const docRef = doc(db, 'users', userId);
    const document = await getDoc(docRef);

    if (document.exists()) {
      return {
        id: document.id,
        ...document.data()
      } as UserData;
    }
  } catch (e) {
    throw new Error('Document not found: ' + e);
  }
};

export const getCompanyDocument = async (userId: string) => {
  try {
    const docRef = doc(db, 'companies', userId);
    const document = await getDoc(docRef);

    if (document.exists()) {
      return {
        id: document.id,
        ...document.data()
      } as CompanyData;
    }
  } catch (e) {
    throw new Error('Document not found: ' + e);
  }
};

export const getClientDocument = async <T>(userId: string) => {
  try {
    const docRef = doc(db, 'company_clients', userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();

      return {
        ...data
      } as T;
    }
  } catch (e) {
    throw new Error('Document not found: ' + e);
  }
};

export const getCompanyClients = async <T extends { id?: string }>(companyId: string, onlyOwn = true) => {
  const clients: T[] = [];
  // Create a reference to the cities collection
  const clientsRef = collection(db, 'company_clients');
  const currentUserId = store.getters['users/userId'];
  const isAdmin = store.getters['users/isCurrentUserAccountOwnerOrAdmin'];

  let secondKey = undefined;
  let secondValue = undefined;

  if (onlyOwn && !isAdmin) {
    secondKey = 'currentCoach';
    secondValue = currentUserId;
  }

  // Create a query against the collection.
  let q = query(clientsRef, where('companyId', '==', companyId), orderBy('createdAt', 'desc'));
  if (secondKey && secondValue) {
    q = query(clientsRef, where('companyId', '==', companyId), where(secondKey, '==', secondValue), orderBy('createdAt', 'desc'));
  }
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    const data = doc.data();

    if (!data.availablePtHours) {
      data.availablePtHours = 0;
    }
    if (!data.usedPtHours) {
      data.usedPtHours = 0;
    }

    clients.push({
      ...data,
      id: doc.id
    } as T);
  });
  return clients;
};

export const getCompanyUsers = async <T extends { id?: string }>(companyId: string) => {
  const users: T[] = [];
  // Create a reference to the cities collection
  const clientsRef = collection(db, 'users');

  // Create a query against the collection.
  const q = query(clientsRef, where('companyId', '==', companyId));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    const data = doc.data();

    users.push({
      ...data,
      id: doc.id
    } as T);
  });
  return users;
};

export const getDocumentFromId = async <T extends { id?: string }>(collectionName: string, docId: string) => {
  try {
    const docRef = doc(db, collectionName, docId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      return {
        ...data,
        id: docId
      } as T;
    } else {
      throw new Error(`Document does not exist! - $${docId}`);
    }
  } catch (e) {
    throw new Error('Error in getDocumentFromId: ' + e);
  }
};

export const updateDocument = async <T extends { id?: string }>(docPath: string, docData: T, docId: string) => {
  const docRef = doc(db, docPath, docId);
  try {
    await updateDoc(docRef, docData);
    return true;
  } catch (e) {
    throw new Error('Error updating document' + e);
  }
};

export const updateDocumentArrayValue = async (docPath: string, key: string, value: string, docId: string) => {
  const docRef = doc(db, docPath, docId);
  try {
    await updateDoc(docRef, {
      [key]: arrayUnion(value)
    });
    return true;
  } catch (e) {
    throw new Error('Error updating document: ' + e);
  }
};

export const updateDocumentArrayRemove = async (docPath: string, key: string, value: string, docId: string) => {
  const docRef = doc(db, docPath, docId);
  try {
    await updateDoc(docRef, {
      [key]: arrayRemove(value)
    });
    return true;
  } catch (e) {
    throw new Error('Error updating document: ' + e);
  }
};

/**
 * Template Tasks
 */
export const getTasksFromTemplateCategory = async <T extends { id?: string }>(companyId: string, categoryId: string) => {
  const items: T[] = [];
  // Create a reference to the cities collection
  const clientsRef = collection(db, `task_templates/${companyId}/tasks`);

  // Create a query against the collection.
  const q = query(clientsRef, where('categoryId', '==', categoryId));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    items.push({
      id: doc.id,
      ...doc.data()
    } as T);
  });
  return items;
};

export const getAllTemplateTasks = async (companyId: string) => {
  const tasks: Task[] = [];
  const querySnapshot = await getDocs(query(collection(db, 'templates_tasks'), where('companyId', '==', companyId), orderBy('createdAt', 'desc')));
  querySnapshot.forEach((doc) => {
    tasks.push({
      id: doc.id,
      ...doc.data()
    } as Task);
  });
  return tasks;
};

/**
 * Template Lessons
 */
export const getAllTemplateLessons = async (companyId: string) => {
  const lessons: Lesson[] = [];
  const querySnapshot = await getDocs(query(collection(db, 'templates_lessons'), where('companyId', '==', companyId), orderBy('createdAt', 'desc')));
  querySnapshot.forEach((doc) => {
    lessons.push({
      ...doc.data(),
      id: doc.id
    } as Lesson);
  });
  return lessons;
};

/**
 * Template Measurements
 */

export const getAllGlobalMeasurementsTemplates = async () => {
  const templates: MeasurementTemplateItem[] = [];
  const querySnapshot = await getDocs(collection(db, 'global_measurement_templates'));
  querySnapshot.forEach((doc) => {
    templates.push({
      id: doc.id,
      ...doc.data()
    } as MeasurementTemplateItem);
  });
  return templates;
};

export const getAllMeasurementsTemplates = async (companyId: string) => {
  const templates: MeasurementTemplateItem[] = [];
  const querySnapshot = await getDocs(query(collection(db, `measurement_templates/${companyId}/templates`), orderBy('createdAt', 'desc')));

  querySnapshot.forEach((doc) => {
    templates.push({
      id: doc.id,
      ...doc.data()
    } as MeasurementTemplateItem);
  });
  return templates;
};

export const getMeasuresFromTemplateId = async <T extends { id?: string }>(collectionPath: string, templateId: string) => {
  const items: T[] = [];
  const q = query(collection(db, collectionPath), orderBy('createdAt', 'desc'), where('templateId', '==', templateId));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    const data = doc.data();

    if (data.createdAt) {
      data.createdAt = data.createdAt.toDate();
    }

    items.push({
      ...data,
      id: doc.id
    } as T);
  });
  return items;
};

export const getExercisesInAPI = async (limit = 20, offset = 20) => {
  try {
    const exercises = await fetch(`https://wger.de/api/v2/exercise/?limit=${limit}&offset=${offset}&language=2`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Token f313b5845ca5966db84554eb82e6bd0b55714604'
      }
    });
    const results = await exercises.json();
    return results;
  } catch (e) {
    throw new Error('Error in searchExercisesInAPI: ' + e.message);
  }
};

export const getExerciseInAPI = async (id: string) => {
  try {
    const exercises = await fetch(`https://wger.de/api/v2/exerciseinfo/${id}`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Token f313b5845ca5966db84554eb82e6bd0b55714604'
      }
    });
    const results = await exercises.json();
    return results;
  } catch (e) {
    throw new Error('Error in searchExercisesInAPI: ' + e.message);
  }
};

export const getExercisesFromCategory = async <T extends { id?: string }>(companyId: string, categoryId: string) => {
  const items: T[] = [];
  // Create a reference to the cities collection
  const clientsRef = collection(db, 'exercises');

  // Create a query against the collection.
  const q = query(clientsRef, where('categoryId', '==', categoryId), where('companyId', '==', companyId));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    items.push({
      id: doc.id,
      ...doc.data()
    } as T);
  });
  return items;
};

/****** PAGINATED
 *
 */

let latestDoc = null;

export const getDocumentsPaginated = async <T extends { id?: string }>(collectionPath: string, numberOfDocs: number) => {
  const items: T[] = [];
  const q = query(collection(db, collectionPath), orderBy('createdAt', 'desc'), limit(numberOfDocs));
  const querySnapshot = await getDocs(q);
  latestDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
  querySnapshot.forEach((doc) => {
    const data = doc.data();

    items.push({
      ...data,
      id: doc.id
    } as T);
  });

  return items;
};

export const getDocumentsPaginatedNext = async <T extends { id?: string }>(collectionPath: string, numberOfDocs: number) => {
  const items: T[] = [];
  const q = query(collection(db, collectionPath), orderBy('createdAt', 'desc'), startAfter(latestDoc || 0), limit(numberOfDocs));
  const querySnapshot = await getDocs(q);
  latestDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    items.push({
      ...data,
      id: doc.id
    } as T);
  });

  return items;
};

type KeyValueType = {
  key: string;
  value: string | Date | boolean;
  operator: WhereFilterOp;
};

export const queryDocumentsWithLimit = async <T extends { id?: string }>(collectionPath: string, limitCount = 50, ...keyValuePairs: (KeyValueType | undefined)[]) => {
  const items: T[] = [];

  // Filter out undefined entries from the key-value pairs
  const filteredKeyValuePairs = keyValuePairs.filter(Boolean) as KeyValueType[];

  if (filteredKeyValuePairs.length === 0) {
    throw new Error('Cant query, no key or value to filter by');
  }

  const q: Query<DocumentData> = query(collection(db, collectionPath), ...filteredKeyValuePairs.map(({ key, operator, value }) => where(key, operator, value)), limit(limitCount));

  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    const docId = doc.id;
    const data = doc.data();

    items.push({
      ...data,
      id: docId
    } as T);
  });

  return items;
};

export const handleFirebaseError = (error: FirebaseError) => {
  if (error.message.includes('insufficient permissions')) {
    sessionStorage.clear();
    localStorage.clear();
    store.commit('users/setUser', undefined);
    store.commit('users/setCompanyData', undefined);
    store.commit('users/setCompanyUsers', undefined);
    signOut(auth);
    window.location.href = '/';
  }
};
