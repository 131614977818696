import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/templates/lessons',
    name: 'LessonsTemplatesIndex',
    meta: {
      title: 'Lektioner'
    },
    components: {
      default: () => import(/* webpackChunkName: "templates-lessons" */ '../views/Templates/Lessons/Index.vue')
    },
    children: [
      {
        path: '',
        name: 'LessonTemplates',
        meta: {
          title: 'Lektioner'
        },
        components: {
          default: () => import(/* webpackChunkName: "templates-lessons" */ '../views/Templates/Lessons/PageLessons.vue'),
          'sub-navigation': () => import(/* webpackChunkName: "templates-lessons" */ '../views/Templates/Lessons/Navigation.vue')
        }
      },
      {
        path: 'categories',
        name: 'LessonTemplateCategories',
        meta: {
          title: 'Kategorier - Lektioner'
        },
        components: {
          default: () => import(/* webpackChunkName: "templates-lessons" */ '../views/Templates/Lessons/Categories/PageLessonCategories.vue'),
          'sub-navigation': () => import(/* webpackChunkName: "templates-lessons" */ '../views/Templates/Lessons/Navigation.vue')
        }
      }
    ]
  }
];

export default routes;
