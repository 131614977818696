import { CategoryItem } from '@/types/interfaces';

interface State {
  programTemplateCategories: CategoryItem[];
  courseTemplateCategories: CategoryItem[];
  taskTemplateCategories: CategoryItem[];
}

export default {
  state: {
    courseTemplateCategories: [],
    taskTemplateCategories: [],
    programTemplateCategories: []
  },
  getters: {
    courseTemplateCategories(state: State) {
      return state.courseTemplateCategories;
    },
    taskTemplateCategories(state: State) {
      return state.taskTemplateCategories;
    },
    programTemplateCategories(state: State) {
      return state.programTemplateCategories;
    }
  },
  mutations: {
    setCourseTemplateCategories(state: State, categories: CategoryItem[]) {
      state.courseTemplateCategories = categories;
    },
    setTaskTemplateCategories(state: State, categories: CategoryItem[]) {
      state.taskTemplateCategories = categories;
    },
    setProgramTemplateCategories(state: State, categories: CategoryItem[]) {
      state.programTemplateCategories = categories;
    }
  },
  actions: {}
};
