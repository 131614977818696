import { CompanyData, FirebaseFile, UserData } from '@/types/interfaces';
import { SubscriptionTypes } from '@/rules/subscriptionRules';

interface State {
  user?: UserData;
  company: CompanyData;
  companyUsers: UserData[];
}
export default {
  state: {
    user: null,
    company: null,
    companyUsers: [],
    subscription: null
  },
  getters: {
    user(state: State) {
      return state.user;
    },
    userCompanyId(state: State) {
      return state.user.companyId;
    },
    companyName(state: State) {
      return state.company.title;
    },
    userId(state: State) {
      return state.user.userId;
    },
    companyUsers(state: State) {
      return state.companyUsers;
    },
    company(state: State) {
      return state.company;
    },
    companyOwner(state: State) {
      return state.company?.accountOwner ?? undefined;
    },
    currentCompanySubscription(state: State) {
      return state.company.currentSubscription;
    },
    isCurrentUserAccountOwnerOrAdmin(state: State) {
      if (state.user && state.user.userId && state.user.userId === state.company.accountOwner) {
        return state.user.userId === state.company.accountOwner;
      } else if (state.user && state.user.role && state.user.role.toLowerCase() === 'admin') {
        return state.user.role.toLowerCase() === 'admin';
      }
      return false;
    }
  },
  mutations: {
    setUser(state: State, user: UserData) {
      state.user = user;
    },
    setCompanyUsers(state: State, users: UserData[]) {
      state.companyUsers = users;
    },
    setCompanyData(state: State, companyData: CompanyData) {
      state.company = companyData;
    },
    setSubscription(state: State, subscription: SubscriptionTypes) {
      state.company.currentSubscription = subscription;
    },
    setEndedTrial(state: State, endedTrial: boolean) {
      state.company.trialEnded = endedTrial;
    },
    setCompanyLogo(state: State, logo: FirebaseFile) {
      state.company.logo = logo;
    }
  },
  actions: {}
};
