import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/templates/courses',
    name: 'CourseTemplatesIndex',
    meta: {
      title: 'Templates - Forløb'
    },
    components: {
      default: () => import(/* webpackChunkName: "course-templates" */ '../views/Templates/Courses/Index.vue')
    },
    children: [
      {
        path: '',
        name: 'CourseTemplates',
        meta: {
          title: 'Templates - Forløb'
        },
        components: {
          default: () => import(/* webpackChunkName: "course-templates" */ '../views/Templates/Courses/PageIndex.vue'),
          'sub-navigation': () => import(/* webpackChunkName: "course-templates" */ '../views/Templates/Courses/Navigation.vue')
        }
      },
      {
        path: 'categories',
        meta: {
          title: 'Kategorier - Forløbstemplates'
        },
        name: 'CourseTemplateCategories',
        components: {
          default: () => import(/* webpackChunkName: "course-templates" */ '../views/Templates/Courses/Categories/PageCourseTemplateCategories.vue'),
          'sub-navigation': () => import(/* webpackChunkName: "course-templates" */ '../views/Templates/Courses/Navigation.vue')
        }
      }
    ]
  },
  {
    path: '/templates/courses/create',
    name: 'CreateCourseTemplate',
    meta: {
      title: 'Opret ny template - Forløb'
    },
    components: {
      default: () => import(/* webpackChunkName: "course-templates" */ '../views/Templates/Courses/Create/Index.vue')
    }
  },
  {
    path: '/templates/courses/edit/:id',
    name: 'EditCourseTemplate',
    components: {
      default: () => import(/* webpackChunkName: "course-templates" */ '../views/Templates/Courses/Create/Index.vue')
    }
  }
];

export default routes;
