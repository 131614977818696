import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/exercises',
    name: 'Exercises',
    meta: {
      title: 'Øvelsesbibliotek'
    },
    components: {
      default: () => import(/* webpackChunkName: "exercises" */ '../views/Exercises/Index.vue'),
      'sub-navigation': () => import(/* webpackChunkName: "exercises" */ '../views/Exercises/Navigation.vue')
    },
    children: [
      {
        path: '',
        meta: {
          title: 'Øvelsesbibliotek'
        },
        name: 'PageExercises',
        components: {
          default: () => import(/* webpackChunkName: "exercises" */ '../views/Exercises/PageExercises.vue'),
          'sub-navigation': () => import(/* webpackChunkName: "exercises" */ '../views/Exercises/Navigation.vue')
        }
      },
      {
        path: 'categories',
        meta: {
          title: 'Øvelser - Kategorier'
        },
        name: 'PageExerciseCategories',
        components: {
          default: () => import(/* webpackChunkName: "exercises" */ '../views/Exercises/PageExerciseCategories.vue'),
          'sub-navigation': () => import(/* webpackChunkName: "exercises" */ '../views/Exercises/Navigation.vue')
        }
      }
    ]
  },
  {
    path: '/global-exercises',
    name: 'global-exercises',
    meta: {
      title: 'Globalt Øvelsesbibliotek'
    },
    components: {
      default: () => import(/* webpackChunkName: "exercises" */ '../views/AdminGlobalExercises/Index.vue'),
      'sub-navigation': () => import(/* webpackChunkName: "exercises" */ '../views/AdminGlobalExercises/Navigation.vue')
    },
    children: [
      {
        path: '',
        meta: {
          title: 'Øvelsesbibliotek'
        },
        name: 'page-global-exercises',
        components: {
          default: () => import(/* webpackChunkName: "exercises" */ '../views/AdminGlobalExercises/PageExercises.vue'),
          'sub-navigation': () => import(/* webpackChunkName: "exercises" */ '../views/AdminGlobalExercises/Navigation.vue')
        }
      },
      {
        path: 'categories',
        meta: {
          title: 'Øvelser - Kategorier'
        },
        name: 'page-global-exercise-categories',
        components: {
          default: () => import(/* webpackChunkName: "exercises" */ '../views/AdminGlobalExercises/PageExerciseCategories.vue'),
          'sub-navigation': () => import(/* webpackChunkName: "exercises" */ '../views/AdminGlobalExercises/Navigation.vue')
        }
      }
    ]
  }
];

export default routes;
