import { Course, ClientEvent, Lesson, Task } from '@/types/interfaces';

interface State {
  course: Course | null;
  courseTemplate: Course | null;
}

interface LessonData {
  taskId: string;
  lesson: Lesson;
}

export interface LessonEvent {
  lessonId: string;
  eventId: string;
  calendarEventId: string;
}

export default {
  state: {
    course: null,
    courseTemplate: null
  },
  getters: {
    course(state: State) {
      return state.course;
    },
    courseId(state: State) {
      return state.course.id;
    },
    courseTemplate(state: State) {
      return state.courseTemplate;
    }
  },
  mutations: {
    setNewCourseTemplate(state: State, course: Course) {
      state.courseTemplate = {
        ...state.courseTemplate,
        ...course
      };
    },
    setCourse(state: State, course: Course) {
      state.course = course;
    },
    updateCourse(state: State, course: Course) {
      state.course = {
        ...state.course,
        ...course
      };
    },
    updateEvent(state: State, event: ClientEvent) {
      const index = state.course.events.findIndex((courseEvent) => courseEvent.calendarEventId === event.calendarEventId);
      if (index !== -1) {
        const updatedEvents = [...state.course.events]; // Create a shallow copy of the events array
        updatedEvents.splice(index, 1, event); // Replace the old event with the updated one
        state.course.events = updatedEvents; // Assign the updated array to the state
      }
    },
    updateTemplateEvent(state: State, event: ClientEvent) {
      const index = state.courseTemplate.events.findIndex((courseEvent) => courseEvent.calendarEventId === event.calendarEventId);
      if (index !== -1) {
        const updatedEvents = [...state.courseTemplate.events]; // Create a shallow copy of the events array
        updatedEvents.splice(index, 1, event); // Replace the old event with the updated one
        state.courseTemplate.events = updatedEvents; // Assign the updated array to the state
      }
    },
    addEventToCourse(state: State, events: ClientEvent[]) {
      if (!state.course) {
        state.course = {
          ...state.course,
          events: []
        };
      }
      if (!state.course.events) {
        state.course.events = [];
      }
      events.forEach((event) => {
        state.course.events.push(event);
      });
    },
    addEventsToCourseTemplate(state: State, events: ClientEvent[]) {
      if (!state.courseTemplate?.events) {
        state.courseTemplate = {
          ...state.courseTemplate,
          events: []
        };
      }
      events.forEach((event) => {
        state.courseTemplate.events.push(event);
      });
    },
    addLessonToEventInCourse(state: State, lesson: LessonData) {
      const event = state.course.events.find((event) => event.calendarEventId === lesson.taskId) as Task;
      if (event) {
        event.lessons.push(lesson.lesson);
      }
    },
    removeEventFromCourse(state: State, eventId: string) {
      state.course.events = state.course.events.filter((event) => {
        return event.calendarEventId !== eventId;
      });
    },
    removeEventFromCourseTemplate(state: State, calendarEventId: string) {
      state.courseTemplate.events = state.courseTemplate.events.filter((event) => {
        return event.calendarEventId !== calendarEventId;
      });
    },
    addClientsToCourse(state: State, clientsIds: string[]) {
      if (!state.course?.users) {
        state.course = {
          ...state.course,
          users: []
        };
      }
      clientsIds.forEach((clientId) => {
        if (!state.course?.users?.includes(clientId)) {
          state.course.users.push(clientId);
        }
      });
    },
    removeClientFromCourse(state: State, userId: string) {
      state.course.users = state.course.users.filter((uId) => {
        return uId !== userId;
      });
      state.course.userCount = state.course.users.length;
    },
    resetCourse(state: State) {
      state.course = null;
    },
    resetCourseTemplate(state: State) {
      state.courseTemplate = null;
    }
  },
  actions: {}
};
