import { RouteRecordRaw } from 'vue-router';
const routes: Array<RouteRecordRaw> = [
  {
    path: '/courses',
    name: 'Courses',
    meta: {
      title: 'Forløb'
    },
    components: {
      default: () => import(/* webpackChunkName: "courses" */ '../views/Courses/Index.vue')
    },
    children: [
      {
        path: '',
        name: 'PageCourses',
        meta: {
          title: 'Forløb'
        },
        components: {
          default: () => import(/* webpackChunkName: "courses" */ '../views/Courses/PageCourses.vue'),
          'sub-navigation': () => import(/* webpackChunkName: "courses" */ '../views/Courses/Navigation.vue')
        }
      },
      {
        path: 'create',
        name: 'CreateCourse',
        meta: {
          title: 'Opret forløb'
        },
        components: {
          default: () => import(/* webpackChunkName: "courses" */ '../views/Courses/Create/Index.vue')
        }
      },
      {
        path: 'edit/:courseId',
        name: 'EditCourse',
        components: {
          default: () => import(/* webpackChunkName: "courses" */ '../views/Courses/Create/Index.vue')
        }
      },
      {
        path: 'categories',
        name: 'CourseCategories',
        meta: {
          title: 'Forløb - Kategorier'
        },
        components: {
          default: () => import(/* webpackChunkName: "courses" */ '../views/Courses/Categories/PageCourseCategories.vue'),
          'sub-navigation': () => import(/* webpackChunkName: "courses" */ '../views/Courses/Navigation.vue')
        }
      }
    ]
  }
];

export default routes;
