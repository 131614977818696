interface State {
  hasUnsavedChanges;
}

export default {
  state: (): State => {
    return {
      hasUnsavedChanges: false
    };
  },
  getters: {
    hasUnsavedChanges(state: State) {
      return state.hasUnsavedChanges;
    }
  },
  mutations: {
    setHasUnsavedChanges(state: State, hasSaved: boolean) {
      state.hasUnsavedChanges = hasSaved;
    }
  },
  actions: {}
};
