import { ClientUserData, Course, Program } from '@/types/interfaces';
import { getDocumentFromId } from '@/firebase/api';

interface State {
  client?: ClientUserData;
  clientInCourses?: Course[];
  clientInPrograms?: Program[];
}
export default {
  state: {
    client: undefined,
    clientInCourses: undefined,
    clientInPrograms: undefined
  },
  getters: {
    clientInCourses(state: State) {
      return state.clientInCourses;
    },
    clientInPrograms(state: State) {
      return state.clientInPrograms;
    },
    getClient(state: State) {
      return state.client;
    }
  },
  mutations: {
    setClientInCourses(state: State, courses: Course[]) {
      state.clientInCourses = [];
      state.clientInCourses = courses;
    },
    setClientInPrograms(state: State, programs: Program[]) {
      state.clientInPrograms = [];
      state.clientInPrograms = programs;
    },
    setClient(state: State, client: ClientUserData | undefined) {
      state.client = client;
    }
  },
  actions: {
    async fetchClient({ commit }, clientId: string) {
      commit('setClient', undefined);
      try {
        const res = await getDocumentFromId<ClientUserData>('company_clients', clientId);
        if (res) {
          commit('setClient', res);
        }
      } catch (e) {
        throw new Error('Cant fetch client with userId: ' + clientId);
      }
    }
  }
};
