import { createStore } from 'vuex';
import users from './users';
import templates from './templates';
import courses from './courses';
import client from './client';
import programs from './programs';
import general from './general';

import createPersistedState from 'vuex-persistedstate';

export default createStore({
  modules: {
    users: {
      namespaced: true,
      ...users
    },
    templates: {
      namespaced: true,
      ...templates
    },
    courses: {
      namespaced: true,
      ...courses
    },
    client: {
      namespaced: true,
      ...client
    },
    programs: {
      namespaced: true,
      ...programs
    },
    general: {
      namespaced: true,
      ...general
    }
  },
  plugins: [
    createPersistedState({
      paths: ['users', 'general', 'programs']
    })
  ]
});
