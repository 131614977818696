import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/templates/programs',
    name: 'ProgramTemplatesIndex',
    meta: {
      title: 'Templates - Programmer'
    },
    components: {
      default: () => import(/* webpackChunkName: "templates-programs" */ '../views/Templates/Programs/Index.vue')
    },
    children: [
      {
        path: '',
        name: 'ProgramTemplates',
        meta: {
          title: 'Templates - Programmer'
        },
        components: {
          default: () => import(/* webpackChunkName: "templates-programs" */ '../views/Templates/Programs/PagePrograms.vue'),
          'sub-navigation': () => import(/* webpackChunkName: "templates-programs" */ '../views/Templates/Programs/Navigation.vue')
        }
      },
      {
        path: 'categories',
        meta: {
          title: 'Kategorier - Program Templates'
        },
        name: 'ProgramTemplateCategories',
        components: {
          default: () => import(/* webpackChunkName: "templates-programs" */ '../views/Templates/Programs/Categories/PageProgramTemplateCategories.vue'),
          'sub-navigation': () => import(/* webpackChunkName: "templates-programs" */ '../views/Templates/Programs/Navigation.vue')
        }
      }
    ]
  },
  {
    path: '/templates/programs/create',
    name: 'CreateProgramTemplate',
    meta: {
      title: 'Opret ny template - Programmer'
    },
    components: {
      default: () => import(/* webpackChunkName: "templates-programs" */ '../views/Templates/Programs/Create/Index.vue')
    }
  },
  {
    path: '/templates/programs/edit/:programId',
    name: 'EditProgramTemplate',
    meta: {
      title: 'Rediger program'
    },
    components: {
      default: () => import(/* webpackChunkName: "templates-programs" */ '../views/Templates/Programs/Create/Index.vue')
    }
  }
];

export default routes;
