import { signInWithEmailAndPassword, signOut, updateEmail, updatePassword, sendPasswordResetEmail, reauthenticateWithCredential, EmailAuthProvider } from '@firebase/auth';
import { collection, addDoc, getFirestore, setDoc, doc, query, where, getDocs } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import { functions } from '@/firebase/firebase';
import { computed, ref } from 'vue';
import store from '@/store/index';
import { deleteDocument, getCompanyDocument, getDocumentFromId, getUserDocument, queryDocuments, setDocument, updateDocumentArrayRemove } from '@/firebase/api';
import { ClientUserData, CompanyData, RegisterUserData, UserData } from '@/types/interfaces';
import { EMAIL_CLIENT_CREATED, EMAIL_FROM_NAME } from '@/emails/emails';
import { EmailData } from '@/firebase/mails';
import router from '@/router';
import { useRouter } from 'vue-router';

const createUserMetaData = async (data: UserData) => {
  try {
    return await setDoc(doc(getFirestore(), 'users', data.userId), data);
  } catch (e) {
    throw new Error('cant create user meta data: ' + e);
  }
};

const createClientMetaData = async (data: ClientUserData) => {
  try {
    return await setDoc(doc(getFirestore(), 'company_clients', data.userId), data);
  } catch (e) {
    throw new Error('cant create client meta data: ' + e);
  }
};

const createUserCompany = async (data: CompanyData) => {
  try {
    const docRef = await addDoc(collection(getFirestore(), 'companies'), data);
    return {
      success: true,
      id: docRef.id
    };
  } catch (e) {
    throw new Error('cant create company: ' + e);
  }
};

const getAllUsersFromCompany = async (companyId) => {
  const users: UserData[] = [];
  try {
    const q = query(collection(getFirestore(), 'users'), where('companyId', '==', companyId));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      users.push({
        ...doc.data()
      } as UserData);
    });
    return users;
  } catch (e) {
    throw new Error('cant get all users from company' + e);
  }
};

// const callableTestCheck = httpsCallable(functions, 'callableTestCheck');
// const callableRunCoursesProgramCheck = httpsCallable(functions, 'callableRunCoursesProgramCheck');
const setCustomClaim = httpsCallable(functions, 'setCustomClaim');
const sendAdminEmail = httpsCallable(functions, 'triggerSendAdminEmail');
const sendEmail = httpsCallable(functions, 'triggerSendEmail');
const deleteUser = httpsCallable(functions, 'deleteUserProfile');
const createUserAccount = httpsCallable<unknown, { userId: string; user: ClientUserData; error?: boolean; message?: string }>(functions, 'createUserAccount');
const updateUserAccount = httpsCallable(functions, 'updateUser');
const getUserLastLogin = httpsCallable(functions, 'getUserLastLogin');

export const getUserState = async () => {
  const router = useRouter();
  return new Promise<{ user: User | null }>((resolve, reject) => {
    try {
      onAuthStateChanged(getAuth(), async (currentUser) => {
        if (!currentUser) {
          resolve({ user: null });
          return;
        }

        try {
          resolve({
            user: currentUser
          });
        } catch (tokenError) {
          if (tokenError.code === 'auth/user-token-expired' || tokenError.code === 'auth/insufficient-permission') {
            // Token expired, log out and redirect to login
            await signOut(getAuth());
            await router.push('/');
          } else {
            reject('Error getting token: ' + tokenError);
          }
        }
      });
    } catch (error) {
      reject('Error happened in onAuthState: ' + error);
    }
  });
};

export const useAuth = () => {
  const user = ref<User | null>(null);
  const error = ref<Error | null>();
  const isAdmin = ref<boolean>(false);
  const auth = getAuth();

  const isAuthenticated = computed(() => user.value !== null);

  const resetUserPassword = async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (e) {
      throw new Error('cant reset password' + e);
    }
  };

  const logOutUser = async () => {
    sessionStorage.clear();
    localStorage.clear();
    signOut(auth);
    store.commit('users/setUser', undefined);
    store.commit('users/setCompanyData', undefined);
    store.commit('users/setCompanyUsers', undefined);
    await router.push('/');
  };

  const updateUser = async (email: string) => {
    try {
      return await updateEmail(auth.currentUser, email);
    } catch (e) {
      throw new Error('cant update email on user: ' + e);
    }
  };

  const updateEmailAddressOnUser = async (userId: string, email: string) => {
    const functionUrl = 'https://europe-west2-fitnessappsaas.cloudfunctions.net/updateUserEmail';
    const requestBody = { uid: userId, email };

    try {
      const response = await fetch(functionUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        throw new Error(`Failed to update email. Status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error updating email:', error);
      throw new Error(`Error updating email: ${error.message}`);
    }
  };

  const updateAccount = async (user: UserData) => {
    try {
      await setDocument('users', user, user.id);
      await updateUserAccount(user);
      return {
        success: true
      };
    } catch (e) {
      throw new Error('Error in updateAccount: ' + e);
    }
  };

  const updateUserPassword = async (password: string, email: string, oldPassword: string) => {
    try {
      const user = auth.currentUser;

      const credential = EmailAuthProvider.credential(email, oldPassword);
      await reauthenticateWithCredential(user, credential);

      return await updatePassword(auth.currentUser, password);
    } catch (e) {
      throw new Error('cant update passowrd on user: ' + e);
    }
  };

  const currentUserState = async () => {
    return await getUserState();
  };

  const isCurrentUserCompanyOwner = async () => {
    const user = await currentUserState();
    if (!user || !user.user) return false;

    const userDoc = await getUserDocument(user.user.uid);
    const companyDoc = await getCompanyDocument(userDoc.companyId);

    return companyDoc && companyDoc.accountOwner === userDoc.id;
  };

  const getUserDocuments = async (userId: string) => {
    try {
      const userDoc = await getUserDocument(userId);
      const companyDoc = await getCompanyDocument(userDoc.companyId);
      const companyUsers = await getAllUsersFromCompany(userDoc.companyId);

      sessionStorage.setItem('currentUser', JSON.stringify(userDoc));
      sessionStorage.setItem('currentCompany', JSON.stringify(companyDoc));
      sessionStorage.setItem('companyUsers', JSON.stringify(companyUsers));
      store.commit('users/setUser', userDoc);
      store.commit('users/setCompanyData', companyDoc);
      store.commit('users/setCompanyUsers', companyUsers);

      return {
        userDoc,
        companyDoc,
        companyUsers
      };
    } catch (e) {
      throw new Error('cant get user documents: ' + e);
    }
  };

  const loginUser = async (email: string, password: string) => {
    await signInWithEmailAndPassword(auth, email, password);
    const loggedInUser = await getUserState();
    const userDocs = await getUserDocuments(loggedInUser.user.uid);

    if (loggedInUser.user?.uid) {
      return {
        userId: loggedInUser.user?.uid,
        company: userDocs.companyDoc
      };
    }
  };

  const registerClient = async (client: ClientUserData, sendConfirmationEmail = true) => {
    try {
      if (!client.email || !client.tempAppPassword) {
        throw new Error('Missing email or password for client registration');
      }

      try {
        const userDoc = await createUserAccount({
          email: client.email,
          password: client.tempAppPassword
        });

        if (userDoc.data.error) {
          if (userDoc.data.message.includes('User with this email already exists')) {
            throw new Error('En bruger med denne email eksisterer allerede i Coachplan!');
          }
          throw new Error('Error creating client: ' + userDoc.data.message);
        }

        try {
          await setCustomClaim({
            userId: userDoc.data.userId,
            key: 'client'
          });
        } catch (error) {
          throw new Error('Error setting custom claim: ' + error.message);
        }

        try {
          if (!client.firstName || !client.lastName || !client.companyId) {
            throw new Error('Missing client metadata fields');
          }

          const metadata = {
            ...client,
            userId: userDoc.data.userId ?? userDoc.data.user.id,
            image: null,
            createdAt: new Date()
          };

          await createClientMetaData(metadata);

          if (sendConfirmationEmail) {
            sendClientConfirmationEmail(client);
          }

          return {
            userId: userDoc.data.userId,
            ...userDoc.data.user
          } as ClientUserData;
        } catch (error) {
          console.error('Error creating client: ', error);
          throw new Error(error.message);
        }
      } catch (error) {
        throw new Error(error.message);
      }
    } catch (error) {
      console.error('Error in registerClient:', error);
      throw new Error(error.message);
    }
  };

  const sendClientConfirmationEmail = async (client: ClientUserData) => {
    const email: EmailData = {
      email: client.email,
      to: client.email,
      toName: client.firstName,
      subject: EMAIL_CLIENT_CREATED.subject,
      preheader: 'Velkommen til Coachplan',
      fromName: EMAIL_FROM_NAME,
      text: EMAIL_CLIENT_CREATED.text(client)
    };

    try {
      await sendEmail(email);
    } catch (error) {
      throw new Error('Error sending email: ' + error.message);
    }
  };

  const registerUser = async (user: RegisterUserData) => {
    try {
      const userDoc = await createUserAccount({
        email: user.email,
        password: user.password
      });
      if (userDoc.data.error) {
        throw new Error(userDoc.data.message);
      }
      if (userDoc) {
        const companyDoc = await createUserCompany({
          title: user.companyName,
          accountOwner: userDoc.data.userId,
          email: user.email,
          currentSubscription: 'free' // TODO: Fix this
        });
        const isAdmin = await setCustomClaim({
          userId: userDoc.data,
          key: 'admin'
        });
        await createUserMetaData({
          email: user.email,
          userId: userDoc.data.userId,
          firstName: user.firstName,
          lastName: user.lastName,
          isAdmin: !!isAdmin,
          role: isAdmin ? 'admin' : 'coach',
          companyId: companyDoc.id,
          isCompanyOwner: true
        });
      }

      const email: EmailData = {
        subject: 'Velkommen til Coachplan',
        email: user.email,
        to: user.email,
        fromName: 'Coachplan',
        toName: user.firstName,
        text: `Alletiders, vi er så glade for at få dig ombord. Vi er sikre på vi bliver et 
               godt match for hinanden! <br />Kom igang allerede nu ved at logge ind i platformen ved at gå til <a href="https://app.coachplan.dk">Coachplan</a><br/><br />
               Bemærk, for at få den bedste start med Coachplan, anbefaler vi at du ser vores video guides på følgenden link: <a href="https://www.youtube.com/watch?v=D1wDoSTlHeQ&list=PL0oI_R6Qt96w7msshhtmhYKzSQ5HrW1y9">Coachplan Guides</a><br/><br />
               For at logge ind på platformen skal du bruge din email: Brugernavn: ${user.email} og din valgte adgangskode!<br /><br /> Vi glæder os til at se dig på platformen!`
      };
      await sendAdminEmail(email);
      return userDoc;
    } catch (e) {
      throw new Error('cant register user: ' + e);
    }
  };

  const registerUserAccount = async (user: UserData, companyId: string) => {
    const companyName = store.getters['users/companyName'];
    try {
      const userDoc = await createUserAccount({
        email: user.email,
        password: user.password
      });
      await setCustomClaim({
        userId: userDoc.data.userId,
        key: user.role === 'admin' ? 'admin' : 'coach'
      });
      await createUserMetaData({
        email: user.email,
        userId: userDoc.data.userId,
        firstName: user.firstName,
        lastName: user.lastName,
        role: user.role,
        isAdmin: user.role === 'admin' ? true : false,
        companyId: companyId,
        isCompanyOwner: false
      });

      const email = {
        subject: `Velkommen til Coachplan ${user.firstName}`,
        email: user.email,
        to: user.email,
        fromName: 'Coachplan',
        toName: user.firstName,
        text: `Tillykke, ${companyName} har oprettet dig som bruger i Coachplan! Vi er så glade for at få dig ombord. Vi er sikre på vi bliver et 
               godt match for hinanden! <br />Kom igang allerede nu ved at logge ind i platformen ved at gå til <a href="https://app.coachplan.dk">Coachplan</a><br/><br />
               Bemærk, for at få den bedste start med Coachplan, anbefaler vi at du ser vores video guides på følgenden link: <a href="https://www.youtube.com/watch?v=D1wDoSTlHeQ&list=PL0oI_R6Qt96w7msshhtmhYKzSQ5HrW1y9">Coachplan Guides</a><br/><br />
               Du kan logge ind med følgende: Brugernavn: ${user.email} / ${user.password}<br /><br />Vi anbefaler at du ændrer din adgangskode efter første login.<br/><br />Vi glæder os til at se dig på platformen!`
      };
      await sendAdminEmail(email);
      return {
        id: userDoc.data.userId,
        ...user
      };
    } catch (e) {
      throw new Error('error in registerUserAccount: ' + e);
    }
  };

  const deleteUserProfile = async (userId: string) => {
    try {
      await deleteUser(userId);
      return true;
    } catch (e) {
      throw new Error('cant delete user: ' + e);
    }
  };

  const getUserLastLoginDate = async (userId: string) => {
    try {
      return await getUserLastLogin(userId);
    } catch (e) {
      throw new Error('cant get last login date: ' + e);
    }
  };

  const deleteFromCourses = async (removeFromCourses: string[], clientId: string) => {
    for (const courseId of removeFromCourses) {
      try {
        const document = await getDocumentFromId('courses', courseId);
        if (document) {
          await updateDocumentArrayRemove('courses', 'users', clientId, courseId);
        }
      } catch (e) {
        //Do nothing and continue
      }
    }
  };

  const deleteFromPrograms = async (removeFromPrograms: string[], clientId: string) => {
    for (const programId of removeFromPrograms) {
      try {
        const document = await getDocumentFromId('programs', programId);
        if (document) {
          await updateDocumentArrayRemove('programs', 'users', clientId, programId);
        }
      } catch (e) {
        //Do nothing and continue
      }
    }
  };

  const deleteCompanyClient = async (client: ClientUserData): Promise<boolean> => {
    try {
      if (!client || !client.id || client.id === '') {
        throw new Error('missing userId');
      }

      const coursesToDelete = await queryDocuments('company_clients_in_courses', 'clientId', client.id);
      const programsToDelete = await queryDocuments('company_clients_in_programs', 'clientId', client.id);

      await Promise.all([
        ...coursesToDelete.map(async (course) => await deleteDocument('company_clients_in_courses', course.id)),
        ...programsToDelete.map(async (program) => await deleteDocument('company_clients_in_programs', program.id))
      ]);

      if (client.courses?.length) {
        await deleteFromCourses(client.courses, client.id);
      }

      if (client.programs?.length) {
        await deleteFromPrograms(client.programs, client.id);
      }

      await Promise.all([deleteDocument('company_clients', client.id)]);

      return true;
    } catch (error) {
      throw new Error(`Failed to delete client with userId ${client.id}: ${error} - Try reloading the page and try again`);
    }
  };

  const deleteUserAccount = async (userId: string) => {
    try {
      await deleteUserProfile(userId);
      await deleteDocument('users', userId);
      return true;
    } catch (e) {
      throw new Error('cant delete userAccount');
    }
  };

  const resendInvite = async (client: ClientUserData) => {
    try {
      const email = {
        to: client.email,
        toName: client.firstName,
        fromName: 'Coachplan',
        text: `Hej ${client.firstName}.<br />Du mangler stadig at aktivere din bruger som din træner har oprettet til dig. Log ind nedenfor med tidligere fremsendt brugernavn/password.`
      };
      return await sendEmail(email);
    } catch (e) {
      throw new Error('cant resend invite: ' + e);
    }
  };

  const deleteCompanyAccount = async (password: string) => {
    try {
      const user = auth.currentUser;

      const credential = EmailAuthProvider.credential(user.email, password);
      await reauthenticateWithCredential(user, credential);

      const updateUserDoc = {
        markedForDeletion: true
      };

      await setDocument('users', updateUserDoc, user.uid);

      await auth.signOut();
    } catch (error) {
      throw new Error('cant delete companyAccount: ' + error);
    }
  };

  // const callable = async () => {
  //   return await callableRunCoursesProgramCheck();
  // };
  //
  // const test = async () => {
  //   return await callableTestCheck();
  // };

  return {
    updateEmailAddressOnUser,
    getUserLastLoginDate,
    getUserDocuments,
    deleteCompanyAccount,
    user,
    error,
    isAdmin,
    isAuthenticated,
    resetUserPassword,
    deleteCompanyClient,
    deleteUserAccount,
    updateUserPassword,
    updateUser,
    loginUser,
    registerUserAccount,
    registerUser,
    logOutUser,
    registerClient,
    deleteUserProfile,
    resendInvite,
    updateAccount,
    getUserState,
    isCurrentUserCompanyOwner,
    sendClientConfirmationEmail
  };
};
