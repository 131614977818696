import { v4 as uuidv4 } from 'uuid';
import store from '@/store';
import { differenceInYears, format, formatDistanceToNow, parseISO } from 'date-fns';
import { ClientUserData, FirebaseDate } from '@/types/interfaces';
import { da } from 'date-fns/locale';
import type { Timestamp } from 'firebase/firestore';

export const partialTitle = (title: string): string => {
  const parts = title.split(' ');
  if (parts.length > 1) {
    parts.shift();
    return parts.join(' ').toLowerCase();
  } else {
    return title.toLowerCase();
  }
};

export function deepCopy<T>(obj: T): T {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (obj instanceof Date) {
    return new Date(obj.getTime()) as unknown as T;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => deepCopy(item)) as unknown as T;
  }

  // Recursively copy object properties
  const copiedObj: Record<string, unknown> = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      copiedObj[key] = deepCopy(obj[key]);
    }
  }

  return copiedObj as T;
}

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const dateToPrettyDate = (date: Date) => {
  return format(date, 'dd-MM-yyyy');
};

export const isFirebaseDate = (date: Date | string | FirebaseDate) => {
  // @ts-ignore
  if (date && date['seconds']) {
    return true;
  } else {
    return false;
  }
};

export const generateUniqueId = () => {
  return uuidv4();
};

export const getUserFromUserId = (userId: string, client?: ClientUserData) => {
  // @ts-ignore
  const users = store.state.users.companyUsers as UserData[];
  if (users) {
    const user = users.find((user) => {
      return user.userId === userId;
    });
    if (user) {
      return user.firstName + ' ' + user.lastName;
    } else if (client && userId === client.userId) {
      return client.firstName + ' ' + client.lastName;
    }
  }
  return '-';
};

export const getCategoryFromID = <T extends { id?: string; title?: string }>(arr: T[], categoryId: string) => {
  if (arr && arr.length > 0) {
    const category = arr.find((category) => {
      return category.id === categoryId;
    });
    if (category && category.title) {
      if (category.title === '' || category.title === ' ') {
        return '-';
      }
      return category.title;
    }
  }
  return '-';
};

export const calculateAge = (dob: Date): number => {
  return differenceInYears(new Date(), dob);
};

const getRandomNumber = (limit) => {
  return Math.floor(Math.random() * limit);
};

export const generateRandomString = (length: number) => {
  const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result.toLowerCase();
};

export const generateRandomColor = () => {
  const h = getRandomNumber(360);
  return `hsl(${h}deg, 20%, 40%)`;
};

export const getDifferenceBetweenNumbers = (a: number, b: number) => {
  return a - b;
};

export const getYouTubeVideoIdFromUrl = (url: string) => {
  // Our regex pattern to look for a youTube ID
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  //Match the url with the regex
  const match = url.match(regExp);
  //Return the result
  return match && match[2].length === 11 ? match[2] : undefined;
};

export const getYouTubeImageFromVideo = async (url: string) => {
  return `https://img.youtube.com/vi/${getYouTubeVideoIdFromUrl(url)}/mqdefault.jpg`;
};

export const getYouTubeVideoDetails = async (url: string) => {
  const API_KEY = 'YOUR_API_KEY'; // Replace with your YouTube Data API key
  try {
    const videoId = getYouTubeVideoIdFromUrl(url);
    const response = await fetch(`https://www.googleapis.com/youtube/v3/videos?id=${videoId}&part=snippet&key=${API_KEY}`);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    if (data.items.length === 0) {
      throw new Error('No video details found');
    }

    const { title, description } = data.items[0].snippet;
    return { title, description };
  } catch (error) {
    console.error('Error fetching video details:', error);
    throw new Error('Failed to fetch video details');
  }
};

export const isValidYoutubeImageUrl = (url: string) => {
  const pattern = /^https:\/\/img\.youtube\.com\/vi\/(?!undefined)[a-zA-Z0-9_-]{11}\/mqdefault\.jpg$/;
  return pattern.test(url);
};

export const removeUndefinedValuesFromObject = <T>(obj: T): T => {
  Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key]);
  return obj;
};

export const capitalizeWords = (sentence: string): string => {
  // Split the sentence into words
  const words = sentence.split(' ');

  // Capitalize the first letter of each word
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  // Join the capitalized words back into a sentence
  const capitalizedSentence = capitalizedWords.join(' ');

  return capitalizedSentence;
};

export const formatTimeStampToTimeAgo = (timestamp: Timestamp) => {
  // Convert `Timestamp` to JavaScript `Date` object
  const date = new Date(Number(timestamp.seconds) * 1000 + timestamp.nanoseconds / 1e6);

  const relativeTime = formatDistanceToNow(date, { addSuffix: true, locale: da });
  return relativeTime;
};

export const debounce = <T>(fn: T, wait: number) => {
  let timer: ReturnType<typeof setTimeout>;
  return (event: Event) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      if (typeof fn === 'function') {
        fn(event);
      }
    }, wait);
  };
};

export function convertToDate(dateValue: string | Date | Timestamp): Date {
  // Check for null or undefined values
  if (dateValue === null || dateValue === undefined) {
    return new Date(); // Return a default date object
  }

  // Attempt to convert based on type
  if (typeof dateValue === 'string') {
    try {
      // Attempt to parse ISO format first
      return parseISO(dateValue);
    } catch (error) {
      // Handle parsing error (optional)
      console.warn('Error parsing ISO date format:', error);

      // Fallback attempt for string conversion
      return new Date(dateValue); // Create a Date object from the string (may be invalid)
    }
  } else if (dateValue instanceof Date) {
    // Already a Date object, use directly
    return dateValue;
  } else if (typeof dateValue === 'object' && 'seconds' in dateValue && 'nanoseconds' in dateValue) {
    // Handle Firebase timestamp format
    const timestamp = new Date(dateValue.seconds * 1000); // Convert seconds to milliseconds
    timestamp.setMilliseconds(timestamp.getMilliseconds() + Math.floor(dateValue.nanoseconds / 1000000));
    return timestamp;
  } else {
    // If none of the above match, attempt a generic Date object creation
    console.warn('Unable to determine valid date format. Attempting generic Date creation.');
    return new Date(dateValue); // May result in an invalid Date object
  }
}

export const getFileNameFromUrl = (url: string) => {
  // Create a new URL object
  const urlObj = new URL(url);

  // Get the pathname, split it into parts
  const pathParts = urlObj.pathname.split('/');

  // The file name is the last part, but it may include a query string
  const lastPart = pathParts[pathParts.length - 1];

  // Split the last part into two parts at the '?'
  const fileNameParts = lastPart.split('?');

  // The file name is the first part
  const fileName = fileNameParts[0];

  // The file name might be URI encoded, so decode it
  const decodedFileName = decodeURIComponent(fileName);

  // Split the decoded file name by '/' and return the last part
  const fileNamePartsAgain = decodedFileName.split('/');
  const actualFileName = fileNamePartsAgain[fileNamePartsAgain.length - 1];

  return actualFileName;
};

export const getFileExtensionFromUrl = (url: string) => {
  const fileName = getFileNameFromUrl(url);
  // Split the filename by '.' and return the last part
  const parts = fileName.split('.');
  const extension = parts[parts.length - 1];
  return extension;
};

export const isImageUrl = (url: string): boolean => {
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'webp', 'svg'];
  const extension = getFileExtensionFromUrl(url).toLowerCase();
  return imageExtensions.includes(extension);
};
