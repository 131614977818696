import './firebase/firebase';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import * as Sentry from '@sentry/vue';

// @ts-ignore
import VueClickAway from 'vue3-click-away';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import HighchartsVue from 'highcharts-vue';
import Highcharts from 'highcharts';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';

HighchartsNoData(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
const app = createApp(App);
//
// // Check if the environment is localhost or development
const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
const isDevelopment = process.env.NODE_ENV === 'development';

app.config.globalProperties.$log = {
  error(...args) {
    console.error(...args);
  }
};

router.onError((error) => {
  app.config.globalProperties.$log.error('Failure Reason: ', error.message, error);

  if (/ChunkLoadError:.*failed./i.test(error.message)) {
    app.config.globalProperties.$log.error('Reloading Window 1');
    window.location.reload();
  } else if (/Loading.*chunk.*failed./i.test(error.message)) {
    app.config.globalProperties.$log.error('Reloading Window 2');
    window.location.reload();
  }
});

// Initialize Sentry only if not running on localhost or development
if (!isLocalhost && !isDevelopment) {
  Sentry.init({
    app,
    dsn: 'https://520a88b87b7863d02070fc3a46b99824@o4505737306701824.ingest.sentry.io/4505737308143616',
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['app.coachplan.dk', 'https://app.coachplan.dk'],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });
}

// @ts-ignore
app.use(HighchartsVue);
app.use(VueClickAway);
app.use(store);
app.use(router);

app.component('Datepicker', Datepicker);
app.mount('#app');
