import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/programs',
    name: 'Programs',
    meta: {
      title: 'Programmer'
    },
    components: {
      default: () => import(/* webpackChunkName: "programs" */ '../views/Programs/Index.vue'),
      'sub-navigation': () => import(/* webpackChunkName: "programs" */ '../views/Programs/Navigation.vue')
    },
    children: [
      {
        path: '',
        name: 'PagePrograms',
        meta: {
          title: 'Programmer'
        },
        components: {
          default: () => import(/* webpackChunkName: "programs" */ '../views/Programs/PagePrograms.vue'),
          'sub-navigation': () => import(/* webpackChunkName: "programs" */ '../views/Programs/Navigation.vue')
        }
      },
      {
        path: 'edit/:programId',
        name: 'EditProgram',
        meta: {
          title: 'Edit program'
        },
        components: {
          default: () => import(/* webpackChunkName: "programs" */ '../views/Programs/Create/Index.vue')
        }
      },
      {
        path: 'create',
        name: 'CreateProgram',
        meta: {
          title: 'Opret nyt program'
        },
        components: {
          default: () => import(/* webpackChunkName: "programs" */ '../views/Programs/Create/Index.vue')
        }
      },
      {
        path: 'categories',
        meta: {
          title: 'Kategorier - Programmer'
        },
        name: 'PageProgramsCategories',
        components: {
          default: () => import(/* webpackChunkName: "programs" */ '../views/Programs/Categories/PageProgramCategories.vue'),
          'sub-navigation': () => import(/* webpackChunkName: "programs" */ '../views/Programs/Navigation.vue')
        }
      }
    ]
  }
];

export default routes;
