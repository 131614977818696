import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/templates/tasks',
    name: 'TaskTemplatesMain',
    meta: {
      title: 'Opgaver'
    },
    components: {
      default: () => import(/* webpackChunkName: "tasks" */ '../views/Templates/Tasks/Index.vue')
    },
    children: [
      {
        path: '',
        name: 'TaskTemplates',
        meta: {
          title: 'Opgaver'
        },
        components: {
          default: () => import(/* webpackChunkName: "tasks" */ '../views/Templates/Tasks/PageIndex.vue'),
          'sub-navigation': () => import(/* webpackChunkName: "tasks" */ '../views/Templates/Tasks/Navigation.vue')
        }
      },
      {
        path: 'categories',
        name: 'TaskCategories',
        meta: {
          title: 'Kategorier - Opgaver'
        },
        components: {
          default: () => import(/* webpackChunkName: "tasks" */ '../views/Templates/Tasks/Categories/PageTaskCategories.vue'),
          'sub-navigation': () => import(/* webpackChunkName: "tasks" */ '../views/Templates/Tasks/Navigation.vue')
        }
      }
    ]
  },
  {
    path: '/templates/tasks/create',
    name: 'CreateTaskTemplate',
    meta: {
      title: 'Opret ny opgave'
    },
    components: {
      default: () => import(/* webpackChunkName: "tasks" */ '../views/Templates/Tasks/Create/Index.vue')
    }
  },
  {
    path: '/templates/tasks/edit/:id',
    name: 'EditTaskTemplate',
    components: {
      default: () => import(/* webpackChunkName: "tasks" */ '../views/Templates/Tasks/Create/Index.vue')
    }
  }
];

export default routes;
