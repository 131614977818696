import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/templates/measures',
    name: 'MeasuresTemplatesIndex',
    meta: {
      title: 'Målinger'
    },
    components: {
      default: () => import(/* webpackChunkName: "measures" */ '../views/Templates/Measurements/Index.vue')
    },
    children: [
      {
        path: '',
        name: 'MeasuresIndex',
        meta: {
          title: 'Målinger'
        },
        components: {
          default: () => import(/* webpackChunkName: "measures" */ '../views/Templates/Measurements/PageIndex.vue')
        }
      }
    ]
  }
];

export default routes;
